import StarRating from "vue-star-rating";
export default {
    name: "popular-product",
    components: {
      StarRating,
    },
    props: {
        product: {
            type: Object,
            default: () => {},
        },
    },
};
